import { useEffect, useState, useContext } from 'react';
import { UserAuth } from './contexts/AuthContext';
import { db } from '../firebase';
import { updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { ProductContext } from './contexts/ProductContext';
import { FiTrash2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Product from './Product';

const LikedProducts = () => {
	const { t } = useTranslation();
	const { user } = UserAuth();
	const { products } = useContext(ProductContext);
	const [likedProducts, setLikedProducts] = useState(products); // Use local state

	const productRef = doc(db, 'users', `${user?.email}`);

	const removeProductFromLiked = async (passedId) => {
		try {
			const updatedProducts = likedProducts.filter(
				(item) => item.id !== passedId
			);

			setLikedProducts(updatedProducts); // Update the local state

			await updateDoc(productRef, {
				savedProducts: updatedProducts,
			});

			localStorage.setItem('savedProducts', JSON.stringify(updatedProducts));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const loadLikedProducts = () => {
			const savedProductsString = localStorage.getItem('savedProducts');
			if (savedProductsString) {
				const savedProducts = JSON.parse(savedProductsString);
				setLikedProducts(savedProducts); // Update the local state
			}
		};

		loadLikedProducts();

		const unsubscribe = onSnapshot(
			doc(db, 'users', `${user?.email}`),
			(doc) => {
				const likedProducts = doc.data()?.savedProducts;
				if (likedProducts) {
					setLikedProducts(likedProducts); // Update the local state
					localStorage.setItem('savedProducts', JSON.stringify(likedProducts));
				}
			}
		);

		return () => unsubscribe();
	}, [user?.email]);

	return (
		<div className='max-w-screen-lg mx-auto p-4'>
			{likedProducts.length === 0 ? (
				<p className='text-xl text-gray-600 text-center'>{t('saveitemP')}</p>
			) : (
				<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'>
					{likedProducts.map((item) => (
						<div
							key={item.id}
							className='text-black border p-2 shadow-lg rounded-lg flex flex-col justify-between'
						>
							<Product key={item.id} product={item} />
							<button
								onClick={() => removeProductFromLiked(item.id)}
								className='text-black text-center text-lg hover:text-red-600 py-2'
							>
								<FiTrash2 />
							</button>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default LikedProducts;

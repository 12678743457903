import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Navbar from './Navbar';
import CategoryPage from './CategoryPage';
import SearchResult from './SearchResult';
import GlobalStyle from './GlobalStyle';
import Sidebar from './Sidebar';
import ProductDetails from './ProductDetails';
import Login from './Login';
import SignUp from './SignUp';
import Account from './Account';
import ProtectedRoute from './ProtectedRoute';
import CheckoutPage from './CheckoutPage';
import OrderSubmissionPage from './OrderSubmissionPage';
import { ToastContainer } from 'react-toastify';
import ThankYou from './ThankYou';
import LanguageToggle from './LanguageToggle';
import SpecialOfTheWeek from './SpecialOfTheWeek';

const App = () => {
	return (
		<div className='overflow-hidden'>
			<Router>
				<GlobalStyle />
				<Navbar />
				<LanguageToggle />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/login' element={<Login />} />
					<Route path='/signup' element={<SignUp />} />
					<Route
						path='/account'
						element={
							<ProtectedRoute>
								<Account />
							</ProtectedRoute>
						}
					/>
					<Route path='/about' element={<About />} />
					<Route path='/special-of-the-week' element={<SpecialOfTheWeek />} />
					<Route path='/product/:id' element={<ProductDetails />} />
					<Route path='/search-result/:searchId' element={<SearchResult />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/thank-you' element={<ThankYou />} />
					<Route path='/category/:categoryName' element={<CategoryPage />} />
					<Route path='/checkout' element={<CheckoutPage />} />
					<Route path='/order-submission' element={<OrderSubmissionPage />} />
				</Routes>
				<Sidebar />
				<ToastContainer />
				<Footer />
			</Router>
		</div>
	);
};

export default App;

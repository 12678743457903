import { useState, useEffect, createContext } from 'react';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
	const initialCart = JSON.parse(localStorage.getItem('cart')) || [];
	const [cart, setCart] = useState(initialCart);
	const [itemAmount, setItemAmount] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	// Function to update the price when quantity changes
	const updatePriceWithQuantity = (item) => {
		return item.price * item.amount;
	};

	// Calculate and update the total price and item amount
	useEffect(() => {
		const total = cart.reduce(
			(accumulator, currentItem) =>
				accumulator + updatePriceWithQuantity(currentItem),
			0
		);

		// Round the total price to 2 decimal places
		const roundedTotal = parseFloat(total.toFixed(2));

		setTotalPrice(roundedTotal);

		const amount = cart.reduce(
			(accumulator, currentItem) => accumulator + currentItem.amount,
			0
		);
		setItemAmount(amount);

		// Save the updated cart data to localStorage
		localStorage.setItem('cart', JSON.stringify(cart));
	}, [cart]);

	// Helper function to find an item by ID
	const findCartItemById = (id) => cart.find((item) => item.id === id);

	const addToCart = (product, id) => {
		const existingCartItem = findCartItemById(id);

		if (existingCartItem) {
			const updatedCart = cart.map((item) =>
				item.id === id
					? {
							...item,
							amount: item.amount + 1,
							price: parseFloat(
								((item.price * (item.amount + 1)) / item.amount).toFixed(2)
							),
							initialPrice: item.initialPrice || item.price, // Store initial price
					  }
					: item
			);
			setCart(updatedCart);
		} else {
			const newCartItem = {
				...product,
				amount: 1,
				unit: product.unit || 'defaultUnit',
				price: product.price !== undefined ? product.price : 0,
				initialPrice: product.price !== undefined ? product.price : 0,
			};

			setCart([...cart, newCartItem]);
		}
	};

	const increaseAmount = (id, newQuantity) => {
		const existingCartItem = findCartItemById(id);

		if (existingCartItem) {
			const updatedCart = cart.map((item) =>
				item.id === id
					? {
							...item,
							amount: newQuantity,
							price: parseFloat(
								((item.price * newQuantity) / item.amount).toFixed(2)
							),
					  }
					: item
			);
			setCart(updatedCart);
		}
	};

	const decreaseAmount = (id) => {
		const existingCartItem = findCartItemById(id);

		if (existingCartItem) {
			const updatedCart = cart.map((item) =>
				item.id === id
					? { ...item, amount: Math.max(1, item.amount - 1) }
					: item
			);
			setCart(updatedCart);
		}
	};
	const removeFromCart = (id) => {
		const newCart = cart.filter((item) => item.id !== id);
		setCart(newCart);
	};

	const clearCart = () => {
		setCart([]); // Clear the cart by setting it to an empty array
	};

	return (
		<CartContext.Provider
			value={{
				addToCart,
				removeFromCart,
				clearCart,
				increaseAmount,
				decreaseAmount,
				cart,
				itemAmount,
				totalPrice,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export default CartProvider;

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ThankYou = () => {
	const { t } = useTranslation();
	return (
		<div className='min-h-screen flex flex-col justify-center items-center bg-gray-100'>
			<div className='max-w-md mx-auto p-6 md:p-8 bg-white shadow-md rounded-lg text-center'>
				<h2 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-4 md:mb-6'>
					{t('thankyouh2')}
				</h2>
				<p className='text-base md:text-lg text-gray-700 mb-6 md:mb-8'>
					{t('thankyouP')}
				</p>
				<Link
					to='/'
					className='inline-block px-4 md:px-6 py-3 rounded-md text-white bg-blue-500 hover:bg-blue-600 text-base md:text-lg font-semibold transition duration-300'
				>
					{t('backHome')}
				</Link>
			</div>
		</div>
	);
};

export default ThankYou;

import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
	const { t } = useTranslation();
	const contentRef = useRef();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const { signUp } = UserAuth();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!email || !password || !displayName) {
			setErrorMessage('Please fill in all fields.');
			return;
		}

		if (displayName.length < 3 || displayName.length > 15) {
			setErrorMessage('Display Name must be between 3 and 8 characters.');
			return;
		}

		try {
			await signUp(email, password, displayName);
			navigate('/');
		} catch (error) {
			setErrorMessage('An error occurred during sign-up. Please try again.');
			console.log(error);
		}
	};
	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<>
			<div className='w-full h-screen bg-black/90' ref={contentRef}>
				<div className=' w-full px-4 py-24 z-50'>
					<div className='max-w-[450px] h-[600px] mx-auto rounded-lg bg-black text-white'>
						<div className='max-w-[320px] mx-auto py-16'>
							<h1 className='text-3xl font-bold'>{t('createacc')}</h1>
							{errorMessage && (
								<div className='text-red-500'>{errorMessage}</div>
							)}
							<form
								onSubmit={handleSubmit}
								className='w-full flex flex-col py-4'
							>
								<input
									onChange={(e) => setEmail(e.target.value)}
									className='p-3 my-2 bg-gray-700 rounded'
									type='email'
									placeholder={t('emailLabel')}
									autoComplete='email'
								/>
								<input
									onChange={(e) => setPassword(e.target.value)}
									className='p-3 my-2 bg-gray-700 rounded'
									type='password'
									placeholder={t('password')}
									autoComplete='current-password'
								/>
								<input
									onChange={(e) => setDisplayName(e.target.value)}
									className='p-3 my-2 bg-gray-700 rounded'
									type='text'
									placeholder={t('displayName')}
								/>
								<button
									type='submit'
									className='bg-gradient-to-r from-slate-700 to-blue-600 py-3 my-6 rounded font-bold'
								>
									{t('signup')}
								</button>
								<p className='py-1'>
									<span className='text-gray-400'>{t('alreadyClient')}</span>{' '}
									<Link to='/login' className='text-blue-400'>
										{t('signin')}
									</Link>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignUp;

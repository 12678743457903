import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
const About = () => {
	const { t } = useTranslation();
	const contentRef = useRef();
	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);
	const aboutSections = [
		{
			title: t('aboutSection1.title'), // Access translations using the 't' function
			text: t('aboutSection1.text'),
		},
		{
			title: t('aboutSection2.title'),
			text: t('aboutSection2.text'),
		},
		{
			title: t('aboutSection3.title'),
			text: t('aboutSection3.text'),
		},
		{
			title: t('aboutSection4.title'),
			text: t('aboutSection4.text'),
		},
	];

	return (
		<div className='p-4 sm:p-8 flex flex-col' ref={contentRef}>
			<h1 className='text-4xl sm:text-6xl text-center mb-8 uppercase'>
				{t('aboutUs')}
			</h1>

			<div className='lg:grid gap-8 sm:grid-cols-2'>
				{aboutSections.map((section, index) => (
					<div key={index} className='bg-white p-6 rounded-md shadow-md'>
						<h2 className='text-xl sm:text-2xl font-bold mb-4'>
							{section.title}
						</h2>
						<p className='text-sm sm:text-base'>{section.text}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default About;

import { createContext, useState, useEffect } from 'react';
import { UserAuth } from './AuthContext';
import { doc, getDoc, updateDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
export const OrdersContext = createContext();

export const OrdersContextProvider = ({ children }) => {
	const [userOrders, setUserOrders] = useState([]);
	const { user } = UserAuth();

	useEffect(() => {
		const loadOrders = async () => {
			try {
				if (user && user.uid) {
					const userDocRef = doc(db, 'users', user.uid);
					const userOrdersSnapshot = await getDoc(userDocRef);
					if (userOrdersSnapshot.exists()) {
						const userData = userOrdersSnapshot.data();
						setUserOrders(userData.orders || []);
					}
				}
			} catch (error) {
				console.error('Error fetching user orders:', error);
			}
		};

		loadOrders();

		if (user?.uid) {
			const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
				const userData = doc.data();
				if (userData && userData.orders) {
					setUserOrders(userData.orders);
				}
			});

			return () => unsubscribe();
		}
	}, [user]);

	const addOrder = async (order) => {
		try {
			if (user) {
				const userDocRef = doc(db, 'users', user.uid);
				const newOrders = [...userOrders, order];
				setUserOrders(newOrders);
				await setDoc(userDocRef, { orders: newOrders }, { merge: true });
			}
		} catch (error) {
			console.error('Error adding order:', error);
		}
	};

	const handleDeleteOrder = async (userEmail, orderId) => {
		try {
			// Send a DELETE request to the backend to delete the order
			const response = await fetch(`/delete-order/${userEmail}/${orderId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					// Add any necessary headers
				},
			});

			if (response.ok) {
				// Remove the deleted order from the userOrders state
				setUserOrders((prevOrders) =>
					prevOrders.filter((order) => order.orderId !== orderId)
				);
				// Update localStorage with the updated orders
				// localStorage.setItem('savedOrder', JSON.stringify(userOrders));
			} else {
				console.error('Failed to delete order');
			}
		} catch (error) {
			console.error('Error deleting order:', error);
		}
	};

	return (
		<OrdersContext.Provider
			value={{
				userOrders,
				setUserOrders,
				addOrder,
				// removeOrder,
				handleDeleteOrder,
			}}
		>
			{children}
		</OrdersContext.Provider>
	);
};

import LikedProducts from './LikedProducts';
import { useEffect, useState } from 'react';
import { UserAuth } from './contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import InventoryList from './InventoryList';
import GoTopButton from './GoTopButton';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const Account = () => {
	const { t } = useTranslation();
	const { user, resetPassword } = UserAuth();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('likedProducts'); // State to track active tab
	const allowedUserEmails = [
		'johnnyhuang15@hotmail.com',
		'sales@orientaltradingca.com',
		'chaohongh@hotmail.com',
	];

	const handleTabChange = (tabName) => {
		// Check if the active tab is 'inventory' and user email matches
		if (tabName === 'inventory' && !allowedUserEmails.includes(user.email)) {
			// Do not allow access, return or perform any action (in this case, return)
			return;
		}
		setActiveTab(tabName);
	};

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}
	}, [user, navigate]);

	if (!user) {
		// Avoid rendering until authentication status is confirmed
		return null;
	}

	const handleResetPassword = async () => {
		const email = user.email;

		try {
			await resetPassword(email);
			toast.success('Password reset email sent successfully', {
				position: 'bottom-right',
				autoClose: 1000, // Adjust the autoClose time as needed
			});
		} catch (error) {
			toast.error(`Error sending password reset email: ${error.message}`);
		}
	};

	return (
		<div className='w-full h-full text-white'>
			<div className='p-4 md:p-8 flex flex-col justify-center items-center'>
				<h1 className='text-3xl md:text-4xl text-gray-900 font-sans text-center'>
					{t('welcomeBack')}{' '}
					<span className='text-blue-500'>{user?.displayName}</span>!
				</h1>
			</div>

			<div className='flex flex-col items-center mb-5 mx-2'>
				{/* Tab Navigation */}
				<div className='flex flex-row items-center justify-center gap-4 text-center'>
					<div className='flex items-center justify-start text-lg font-bold'>
						<Link
							to='/'
							className='flex items-center text-blue-500 p-2 rounded-md'
						>
							<FiArrowLeft className='mr-2' />
							{t('backHome')}
						</Link>
					</div>
					<select
						value={activeTab}
						onChange={(e) => handleTabChange(e.target.value)}
						className='text-base lg:text-lg xl:text-lg px-3 py-2 md:px-4 md:py-2 lg:px-4 lg:py-2 xl:px-4 xl:py-2 rounded-md border border-blue-400 bg-white text-gray-700' /* Added text color */
					>
						<option value='likedProducts'>{t('likedProduct')}</option>
						{allowedUserEmails.includes(user.email) && (
							<option value='inventory'>Inventory</option>
						)}
						<option value='resetPassword'>{t('resetPassword')}</option>{' '}
						{/* Inserted the Reset Password option */}
					</select>
				</div>
			</div>

			<div className='bg-gray-100 m-4 p-4'>
				{/* LIKED PRODUCTS */}
				{activeTab === t('likedProducts') && (
					<div>
						<LikedProducts />
					</div>
				)}
				{/* INVENTORY */}
				{activeTab === 'inventory' &&
					allowedUserEmails.includes(user.email) && (
						<div>
							{/* Render InventoryList component */}
							<InventoryList />
						</div>
					)}
				{/* RESET PASSWORD */}
				{activeTab === 'resetPassword' && (
					<div className='flex flex-col items-center'>
						<button
							onClick={handleResetPassword}
							className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
						>
							{t('resetPassword')}
						</button>
					</div>
				)}
			</div>
			<GoTopButton />
		</div>
	);
};

export default Account;

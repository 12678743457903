import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown } from 'react-icons/io';

const LanguageToggle = () => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('selectedLanguage', lng); // Store selected language in localStorage
	};

	// Retrieve the selected language from localStorage on page load
	useEffect(() => {
		const savedLanguage = localStorage.getItem('selectedLanguage');
		if (savedLanguage) {
			i18n.changeLanguage(savedLanguage);
		}
	}, [i18n]);

	const goToSpecialOfTheWeek = () => {
		navigate('/special-of-the-week'); // Redirect to the "Special of the Week" page
	};

	return (
		<div className='flex flex-col md:flex-row lg:flex-row items-center justify-center my-4 gap-2'>
			<button
				className='px-4 py-2 md:py-[6px] text-white rounded-md focus:outline-none font-semibold text-lg md:text-base shadow-lg uppercase'
				onClick={goToSpecialOfTheWeek}
				style={{
					background: 'blue',
					minWidth: '150px',
					textAlign: 'center',
					whiteSpace: 'nowrap',
				}}
			>
				{t('special')}
			</button>
			<div className='relative inline-block'>
				<select
					className='block appearance-none w-full  bg-slate-700 px-4 py-2 text-white rounded-lg focus:outline-none cursor-pointer text-center'
					onChange={(e) => changeLanguage(e.target.value)}
					style={{
						paddingRight: '2.5rem',
						color: 'white',
						fontSize: '1rem',
						backgroundColor: 'black',
					}}
				>
					<option value=''>{t('selectLanguage')}</option>
					<option value='en'>ENGLISH</option>
					<option value='fr'>FRANÇAIS</option>
					<option value='zh'>中文</option>
				</select>

				<div
					className='absolute inset-y-0 right-0 flex items-center px-2 text-white'
					style={{ pointerEvents: 'none' }}
				>
					<IoMdArrowDropdown />
				</div>
			</div>
		</div>
	);
};

export default LanguageToggle;

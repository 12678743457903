import { useForm, ValidationError } from '@formspree/react';
import ThankYou from './ThankYou';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
	const contentRef = useRef();
	const { t } = useTranslation();
	const [state, handleSubmit] = useForm('mdorjjel');
	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		if (state.submitting) {
			return;
		}

		handleSubmit(event);
	};

	const isFormEmpty = !formData.name || !formData.email || !formData.message;

	if (state.succeeded) {
		return (
			<>
				<ThankYou />
			</>
		);
	}
	return (
		<div className='p-4 md:p-8 lg:p-12' ref={contentRef}>
			<h2 className='text-2xl font-semibold mb-4'>{t('contactUs')}</h2>
			<p className='mb-6 text-gray-600'>{t('contactFormDescription')}</p>
			<form onSubmit={handleFormSubmit}>
				<div className='mb-4'>
					<label htmlFor='name' className='block mb-2'>
						{t('nameLabel')}
					</label>
					<input
						id='name'
						type='text'
						name='name'
						value={formData.name}
						onChange={handleInputChange}
						className='w-full border rounded-md px-3 py-2'
					/>
					<ValidationError prefix='Name' field='name' errors={state.errors} />
				</div>
				<div className='mb-4'>
					<label htmlFor='email' className='block mb-2'>
						{t('emailLabel')}
					</label>
					<input
						id='email'
						type='email'
						name='email'
						value={formData.email}
						onChange={handleInputChange}
						className='w-full border rounded-md px-3 py-2'
					/>
					<ValidationError prefix='Email' field='email' errors={state.errors} />
				</div>
				<div className='mb-4'>
					<label htmlFor='message' className='block mb-2'>
						{t('messageLabel')}
					</label>
					<textarea
						id='message'
						name='message'
						value={formData.message}
						onChange={handleInputChange}
						className='w-full border rounded-md px-3 py-2 resize-none'
					/>
					<ValidationError
						prefix='Message'
						field='message'
						errors={state.errors}
					/>
				</div>
				<div className='text-center cursor-pointer'>
					<button
						type='submit'
						disabled={isFormEmpty || state.submitting}
						className={`${
							isFormEmpty || state.submitting
								? 'bg-gray-400 cursor-not-allowed'
								: 'bg-blue-500 hover:bg-blue-600'
						} text-white px-4 py-2 rounded-md`}
					>
						{t('submitButton')}
					</button>
				</div>
			</form>
		</div>
	);
};

export default Contact;

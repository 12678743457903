import { useParams, Link } from 'react-router-dom';
import { useContext, useEffect, useRef } from 'react';
import { ProductContext } from './contexts/ProductContext';
import Product from './Product';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';

const CategoryPage = () => {
	const contentRef = useRef();
	const { t } = useTranslation();
	const { categoryName } = useParams(); // Get the category name from the route parameter
	const { products } = useContext(ProductContext);

	// Filter products based on the categoryName from the route
	const filteredProducts = products.filter(
		(item) => item.category === categoryName
	);
	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<div
			className='p-2 flex flex-col items-center justify-center'
			ref={contentRef}
		>
			<div className='flex flex-col lg:flex-row lg:items-center lg:justify-between items-start justify-center p-4'>
				<Link
					to='/'
					className='flex items-center text-blue-500 p-2 text-lg rounded-md mb-2 lg:mb-0 lg:mr-4 hover:bg-blue-50 transition-colors duration-300'
				>
					<FiArrowLeft className='mr-2' />
					{t('backHome')}
				</Link>
				<h1 className='text-white bg-blue-400 px-2 py-1 rounded text-lg'>
					{t(categoryName)}
				</h1>
			</div>

			<div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-[25px] w-full md:w-full lg:w-full xl:w-full mx-auto md:mx-0'>
				{filteredProducts.map((product) => (
					<div
						key={product.id}
						className='flex flex-col justify-between border border-gray-300 rounded-lg p-4 hover:shadow-md relative'
					>
						<Product key={product.id} product={product} />
					</div>
				))}
			</div>
		</div>
	);
};

export default CategoryPage;

import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdClose, IoMdAdd, IoMdRemove } from 'react-icons/io';
import { CartContext } from './contexts/CartContext';

const CartItem = ({ item }) => {
	const { id, name, image, amount, unit, price, initialPrice } = item;
	const { removeFromCart, increaseAmount } = useContext(CartContext);
	const [manualQuantity, setManualQuantity] = useState(amount);
	const [error, setError] = useState('');

	const handleManualQuantityChange = (e) => {
		const inputText = e.target.value;

		if (!inputText.trim()) {
			// Handle empty input
			setManualQuantity(0); // Set the quantity to 0 if the input is empty
			setError(''); // Clear any previous error messages
			return;
		}

		const newQuantity = parseInt(inputText);

		if (!isNaN(newQuantity) && newQuantity >= 0) {
			setManualQuantity(newQuantity);
			increaseAmount(id, newQuantity);
			setError('');
		} else {
			// setManualQuantity(previousValidQuantity);
			setError('Please enter a valid positive number');
		}
	};

	const handleIncrement = () => {
		const newQuantity = manualQuantity + 1;
		setManualQuantity(newQuantity);

		// Update the cart item quantity
		increaseAmount(id, newQuantity);
	};

	const handleDecrement = () => {
		if (manualQuantity > 1) {
			const newQuantity = manualQuantity - 1;
			setManualQuantity(newQuantity);

			// Update the cart item quantity
			increaseAmount(id, newQuantity);
		} else if (manualQuantity === 1) {
			// If the quantity is decreased to zero, remove the item from the cart
			setManualQuantity(0); // Update the input field to reflect the change
			removeFromCart(id);
		}
	};

	return (
		<div className='flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light text-gray-500'>
			<div className='w-full min-h-[150px] flex items-center gap-x-4'>
				<Link to={`/product/${id}`}>
					<img className='max-w-[80px]' src={image} alt='food cart' />
				</Link>
				<div className='w-full flex flex-col'>
					<div className='flex justify-between mb-2'>
						<p className='text-sm uppercase font-medium max-w-[240px]'>
							{name}
							<span>/{unit}</span>
						</p>
						<div
							onClick={() => removeFromCart(id)}
							className='text-xl cursor-pointer'
						>
							<IoMdClose className='text-red-600 hover-text-red-500 transition' />
						</div>
					</div>
					<div className='flex items-center gap-x-2 h-[36px]'>
						<div className='flex items-center text-primary font-medium'>
							<button onClick={handleDecrement}>
								<IoMdRemove />
							</button>
							<input
								className='w-16 p-2 text-center border rounded'
								type='number'
								value={manualQuantity === 0 ? '0' : String(manualQuantity)}
								onChange={handleManualQuantityChange}
							/>
							{error && <p className='text-red-500'>{error}</p>}
							<button onClick={handleIncrement}>
								<IoMdAdd />
							</button>
							<span className='text-gray-600 p-2'>{unit}</span>
						</div>
					</div>
					<div className='text-center my-2'>
						<p>
							Current Price:{' '}
							{initialPrice !== undefined &&
								initialPrice !== null &&
								`$${initialPrice.toFixed(2)}`}
						</p>
					</div>
					<div className=' text-red-600 font-bold'>
						{price !== undefined && price !== null && `$${price.toFixed(2)}`}{' '}
						{/* Display current price */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartItem;

import { useContext, useRef, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { CartContext } from './contexts/CartContext';
import { ProductContext } from './contexts/ProductContext';
import Loading from './Loading';
import { showCartItemAddedNotification } from './CartItemAddedNotification';
import fallbackImage from '../purple.jpeg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const ProductDetails = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const contentRef = useRef();
	const { products } = useContext(ProductContext);
	const { addToCart } = useContext(CartContext);

	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	const product = products.find((item) => item.id === id);

	if (!product) {
		return <Loading />;
	}

	const { name, description, category, image, imageCarousel, price } = product;

	const allImages = imageCarousel ? [image, ...imageCarousel] : [image];

	const handleAddToCart = () => {
		addToCart(product, product.id);
		showCartItemAddedNotification(product.name);
	};

	return (
		<section className='py-10 lg:py-20 rounded-md' ref={contentRef}>
			<div className='container mx-auto p-4 bg-white/80 shadow-lg'>
				<div className='mb-4 flex items-center justify-start text-lg font-bold'>
					<Link
						to='/'
						className='flex items-center text-blue-500 p-2 rounded-md'
					>
						<FiArrowLeft className='mr-2' />
						{t('backHome')}
					</Link>
				</div>

				<div className='flex flex-col md:flex-row lg:flex-row justify-center items-center'>
					{/* Image container */}
					<div className='md:w-[500px] lg:w-[500px] w-[400px] p-3'>
						{allImages.length > 1 ? (
							<div className='shadow-lg bg-white'>
								<Carousel
									showArrows={true}
									showStatus={false}
									showThumbs={false}
									infiniteLoop={true}
								>
									{allImages.map((img, index) => (
										<div key={index}>
											<img
												//carousel image
												src={img}
												alt={`${name} - productDetail ${index + 1}`}
												loading='lazy'
												onError={(e) => {
													e.target.src = fallbackImage;
												}}
												className='h-[400px] md:h-[500px] lg:h-[500px] object-cover rounded'
											/>
										</div>
									))}
								</Carousel>
							</div>
						) : (
							//single image
							<img
								className='w-[400px] md:w-[500px] lg:w-[500px] h-[400px] md:h-[500px] lg:h-[500px] object-cover rounded'
								src={image}
								alt={name}
								loading='lazy'
								onError={(e) => {
									e.target.src = fallbackImage;
								}}
							/>
						)}
					</div>

					{/* Description container */}
					<div className='bg-white flex flex-col justify-between items-center w-[400px] md:w-1/2 lg:w-1/2 h-[400px] md:h-[500px] lg:h-[500px] shadow-lg rounded-md'>
						<h1 className='text-lg lg:text-3xl xl:text-3xl font-semibold text-black p-2 uppercase text-center'>
							{name}
							<span className='mx-2 text-sm text-gray-400 p-1 rounded-full'>
								{t(category)}
							</span>
						</h1>
						<div className='flex items-center justify-start my-2'>
							<p className='font-bold text-base md:text-base lg:text-lg xl:text-lg'>
								${price}
							</p>
						</div>
						<div className='text-gray-700 mx-2 text-base lg:text-lg'>
							{description.map((desc, index) => (
								<p key={index} className='mb-3 italic'>
									{desc}
								</p>
							))}
						</div>

						<button
							onClick={handleAddToCart}
							className='text-white bg-red-600 w-1/2 mb-4 py-4 font-bold rounded uppercase hover:bg-opacity-90 text-center'
						>
							{t('addToCart')}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProductDetails;

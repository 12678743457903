import { useNavigate } from 'react-router-dom';
import logo from '../Oriental-Trading.png';

const Logo = () => {
	const navigate = useNavigate();

	return (
		<div
			className='cursor-pointer'
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				navigate('/');
			}}
		>
			<img className='w-[75px] h-[75px] ml-5' src={logo} alt='Logo' />
		</div>
	);
};

export default Logo;

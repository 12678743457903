import { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../../firebase';
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	updateProfile,
	sendPasswordResetEmail,
} from 'firebase/auth';
import {
	setDoc,
	doc,
	collection,
	query,
	where,
	getDocs,
} from 'firebase/firestore';

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
	const [user, setUser] = useState({});

	async function signUp(email, password, displayName) {
		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);

			// Set the display name
			await updateProfile(userCredential.user, { displayName });

			// Create a document for the user in the 'users' collection
			await setDoc(doc(db, 'users', email), {
				savedProducts: [],
			});
		} catch (error) {
			console.error('Error signing up:', error.message);
			// Handle the error as needed
		}
	}

	// Function to handle password reset
	async function resetPassword(email) {
		try {
			await sendPasswordResetEmail(auth, email);
			// Password reset email sent successfully
			// You can provide user feedback or handle success as needed
			console.log('Password reset email sent');
		} catch (error) {
			console.error('Error sending password reset email:', error.message);
			// Handle the error as needed
		}
	}

	// Function to fetch user orders
	async function fetchUserOrders(userId) {
		try {
			const userOrdersSnapshot = await getDocs(
				query(collection(db, 'orders'), where('userId', '==', userId))
			);
			const userOrders = userOrdersSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			return userOrders;
		} catch (error) {
			console.error('Error fetching user orders:', error);
			return []; // Return an empty array or handle the error as needed
		}
	}

	// Function to update orders in the context
	async function updateOrders(userId) {
		const orders = await fetchUserOrders(userId);
		setUser((prevUser) => ({
			...prevUser,
			orders, // Update user context with fetched orders
		}));
	}

	function logIn(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}

	function logOut() {
		return signOut(auth);
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
		});
		return () => {
			unsubscribe();
		};
	});

	return (
		<AuthContext.Provider
			value={{
				signUp,
				user,
				logIn,
				logOut,
				resetPassword,
				fetchUserOrders,
				updateOrders,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function UserAuth() {
	return useContext(AuthContext);
}

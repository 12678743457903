import { useContext, useState, useEffect, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from './contexts/CartContext';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import CartItem from './CartItem';

const CheckoutPage = () => {
	const { t } = useTranslation();
	const { cart, clearCart } = useContext(CartContext);
	const [totalPrice, setTotalPrice] = useState(0);
	const navigate = useNavigate();

	// State for user information
	const [userInfo, setUserInfo] = useState({
		name: '',
		email: '',
		phoneNumber: '',
		address: '',
		city: '',
		province: '',
		postalCode: '',
		note: '',
	});

	const [validationError, setValidationError] = useState(null);
	const [submittingOrder, setSubmittingOrder] = useState(false);

	useEffect(() => {
		let total = 0;

		if (cart && cart.length > 0) {
			total = cart.reduce(
				(accumulator, currentItem) =>
					accumulator + (currentItem.initialPrice * currentItem.amount || 0),
				0
			);
		}

		setTotalPrice(total);
	}, [cart]);

	const handleUserInfoChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({
			...userInfo,
			[name]: value,
			note: name === 'note' ? value || '' : userInfo.note,
			email: name === 'email' ? value || '' : userInfo.email,
		});
	};

	const handleSubmitOrder = async () => {
		if (
			!userInfo.name ||
			!userInfo.phoneNumber ||
			!userInfo.address ||
			!userInfo.city ||
			!userInfo.province ||
			!userInfo.postalCode ||
			!userInfo.email
		) {
			setValidationError('* Please fill in all required fields.');
		} else if (cart.length === 0) {
			setValidationError(
				'Please add items to your cart before submitting the order.'
			);
		} else {
			setValidationError(null);
			setSubmittingOrder(true);

			try {
				// Make a POST request to your backend endpoint to submit the order
				const response = await fetch(
					'https://oriental-trading-ca-server.vercel.app/submit-order',
					// '/submit-order',
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							orderData: {
								name: userInfo.name,
								email: userInfo.email,
								phoneNumber: userInfo.phoneNumber,
								address: userInfo.address,
								city: userInfo.city,
								province: userInfo.province,
								postalCode: userInfo.postalCode,
								note: userInfo.note,
							},
							cart: cart,
						}),
					}
				);

				if (response.ok) {
					const data = await response.json();
					if (data.success) {
						// Clear the cart and navigate to the order submission page
						clearCart();
						navigate('/order-submission', {
							state: {
								orderData: userInfo,
								cart: cart,
								invoiceNumber: data.invoiceNumber,
							},
						});
						setSubmittingOrder(false);
					}
				} else {
					throw new Error('Failed to submit order');
				}
			} catch (error) {
				console.error('Error submitting order:', error);
				setSubmittingOrder(false);
				// Handle error state or display a notification to the user
			}
		}
	};

	useEffect(() => {
		// Add an event listener for the beforeunload event
		const handleBeforeUnload = (event) => {
			// Check if the download is still in progress
			if (submittingOrder) {
				// Prevent immediate navigation
				const message =
					'Your submission is still in progress. Are you sure you want to leave?';
				event.returnValue = message;
				return message;
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			// Remove the event listener when the component is unmounted
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [submittingOrder]);

	return (
		<div className='p-4'>
			<h1 className='text-blue-500 text-lg uppercase font-bold p-2 mb-2 text-center'>
				{t('checkoutBtn')}
			</h1>

			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
				{cart.length === 0 ? (
					<div className='text-center text-gray-600'>
						<Link to='/'>
							<div className='flex items-center text-center gap-2'>
								<AiOutlinePlusCircle className='text-2xl text-primary' />
								Please add items to your cart.
							</div>
						</Link>
					</div>
				) : (
					<>
						{cart.map((item) => (
							<div
								key={item.id}
								className=' bg-white border rounded-md shadow-md'
							>
								<CartItem item={item} key={item.id} />
							</div>
						))}
					</>
				)}
			</div>
			<div className='mt-4 border shadow-lg bg-slate-200 w-fit p-2 rounded'>
				<p className='text-lg font-semibold uppercase'>
					{t('totalPrice')}:{' '}
					<span className='text-red-600'>{`$${totalPrice.toFixed(2)}`}</span>
				</p>
			</div>

			<div className='mt-8'>
				<h2 className='text-xl font-semibold mb-2'>{t('userInfo')}</h2>
				<form>
					<div className='mb-4'>
						<label htmlFor='name' className='block text-gray-600'>
							{t('nameLabel')}
							<span className='text-red-500'>*</span>
						</label>
						<input
							type='text'
							id='name'
							name='name'
							value={userInfo.name}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='email' className='block text-gray-600'>
							{t('emailLabel')}
							<span className='text-red-500'>*</span>
						</label>
						<input
							type='email'
							id='email'
							name='email'
							value={userInfo.email}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='phoneNumber' className='block text-gray-600'>
							{t('phone')}
							<span className='text-red-500'>*</span>
						</label>
						<input
							type='tel'
							id='phoneNumber'
							name='phoneNumber'
							value={userInfo.phoneNumber}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='address' className='block text-gray-600'>
							Address:<span className='text-red-500'>*</span>
						</label>
						<textarea
							id='address'
							name='address'
							value={userInfo.address}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
							style={{ resize: 'none', minHeight: '80px' }} // Adjust the height as needed
						/>
						<label htmlFor='city' className='block text-gray-600 mt-2'>
							City:<span className='text-red-500'>*</span>
						</label>
						<input
							type='text'
							id='city'
							name='city'
							value={userInfo.city}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2 mb-2'
							style={{ minHeight: '40px' }} // Adjust the height as needed
						/>
						<label htmlFor='province' className='block text-gray-600'>
							Province:<span className='text-red-500'>*</span>
						</label>
						<input
							type='text'
							id='province'
							name='province'
							value={userInfo.province}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2 mb-2'
							style={{ minHeight: '40px' }} // Adjust the height as needed
						/>
						<label htmlFor='postalCode' className='block text-gray-600'>
							Postal Code:<span className='text-red-500'>*</span>
						</label>
						<input
							type='text'
							id='postalCode'
							name='postalCode'
							value={userInfo.postalCode}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
							style={{ minHeight: '40px' }} // Adjust the height as needed
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='note' className='block text-gray-600'>
							Note:<span className='text-xs'>{t('optional')}</span>
						</label>
						<textarea
							id='note'
							name='note'
							value={userInfo.note}
							onChange={handleUserInfoChange}
							className='w-full border rounded-md px-3 py-2'
							rows='4'
						/>
					</div>
				</form>

				{validationError && (
					<div className='text-red-600 mb-4'>{validationError}</div>
				)}
			</div>

			<div className='mt-4'>
				<h2 className='pb-2 text-slate-700 text-center text-lg md:text-xl lg:text-2xl'>
					<span className='category-name'>{t('avis')}</span>
					<br />
					<br />
					{t('notice')}
				</h2>

				<button
					onClick={handleSubmitOrder}
					disabled={submittingOrder}
					className='bg-blue-400 text-white px-4 py-2 rounded-md hover:bg-blue-500'
				>
					{submittingOrder ? 'Processing...' : <>{t('submitBtn')}</>}
				</button>
			</div>
		</div>
	);
};

export default memo(CheckoutPage);

import { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProductContext } from './contexts/ProductContext';
import ProductNotFound from './ProductNotFound';
import { FiArrowLeft } from 'react-icons/fi';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';
import Product from './Product';
const SearchResult = () => {
	const contentRef = useRef();
	const { t } = useTranslation();
	const { searchId } = useParams();
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(true);

	const { products } = useContext(ProductContext);

	useEffect(() => {
		const filteredProducts = products.filter((product) =>
			product.name.toLowerCase().includes(searchId.toLowerCase())
		);

		setResults(filteredProducts);
		setLoading(false);
	}, [searchId, products]);

	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<>
			<div className='flex flex-col lg:flex-row lg:items-center justify-between p-4'>
				<Link
					to='/'
					className='flex items-center text-blue-500 p-2 text-lg rounded-md mb-2 lg:mb-0 lg:mr-4 hover:bg-blue-50 transition-colors duration-300'
					ref={contentRef}
				>
					<FiArrowLeft className='mr-2' />
					{t('backHome')}
				</Link>
			</div>

			<div className='p-2 flex justify-center'>
				<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 w-full'>
					{loading ? (
						<div className='flex justify-center items-center'>
							<Loading />
						</div>
					) : results.length > 0 ? (
						results.map((item) => (
							<div
								key={item.id}
								className='border border-gray-300 p-2 rounded-lg overflow-hidden shadow-md hover:shadow-lg relative'
							>
								<Product key={item.id} product={item} />
							</div>
						))
					) : (
						<div className='col-span-full'>
							<ProductNotFound />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SearchResult;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import ProductProvider from './components/contexts/ProductContext';
import SidebarProvider from './components/contexts/SidebarContext';
import CartProvider from './components/contexts/CartContext';
import { AuthContextProvider } from './components/contexts/AuthContext';
import { OrdersContextProvider } from './components/contexts/OrdersContext';
import i18n from 'i18next'; // Import i18n
import { initReactI18next } from 'react-i18next';
import resources from './translations'; // Adjust the path according to your project structure

// Translations
i18n.use(initReactI18next).init({
	resources,
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AuthContextProvider>
		<OrdersContextProvider>
			<SidebarProvider>
				<CartProvider>
					<ProductProvider>
						<React.StrictMode>
							<App />
						</React.StrictMode>
					</ProductProvider>
				</CartProvider>
			</SidebarProvider>
		</OrdersContextProvider>
	</AuthContextProvider>
);

import { RiErrorWarningLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const ProductNotFound = () => {
	const { t } = useTranslation();
	return (
		<div className='flex items-center justify-center h-full'>
			<div className='bg-red-100 border border-red-400 rounded-lg p-4 md:p-6 max-w-md text-center'>
				<RiErrorWarningLine className='text-red-500 text-4xl md:text-5xl mb-3 md:mb-4' />
				<h2 className='text-lg md:text-xl font-semibold mb-2 text-red-600'>
					{t('productNotFound')}
				</h2>
				<p className='text-sm md:text-base text-red-700'>{t('productP')}</p>
			</div>
		</div>
	);
};

export default ProductNotFound;

import { useState } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Header from './Header';
import Hero from './Hero';
import { UserAuth } from './contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
	const { t } = useTranslation();
	const [nav, setNav] = useState(false);
	const { user, logOut } = UserAuth();
	const navigate = useNavigate();
	const toggleNav = () => {
		setNav(!nav);
	};

	const handleLogout = async () => {
		try {
			await logOut();
			navigate('/');
			setNav(false); // Close the mobile menu after logging out
		} catch (error) {
			console.log(error);
		}
	};

	const closeMobileMenu = () => {
		setNav(false);
	};

	return (
		<>
			<div className='relative'>
				{/* Hero component positioned here */}
				<Hero />

				{/* Navbar positioned on top of the Hero component */}
				<div className='absolute top-0 left-0 w-full'>
					<div className='max-w-[1640px] mx-auto flex justify-between items-center p-1'>
						{/* Left side */}
						<div className='flex items-center'>
							<div
								onClick={toggleNav}
								className='text-white cursor-pointer lg:hidden'
							>
								<AiOutlineMenu size={30} />
							</div>
							<h1 className='lg:w-[75px] lg:h-[75px] md:w-20 md:h-20 lg:block mx-auto'>
								<Logo />
							</h1>
						</div>

						<div className=''>
							<SearchBar />
						</div>

						{/* Center */}
						<div className=' lg:flex lg:right-[12px] lg:relative'>
							<Header />
						</div>
						{/* Mobile SideBar*/}
						{nav && (
							<div
								className='bg-white fixed transition-all duration-500 ease-in-out w-full h-screen z-10 top-0 left-0'
								onClick={closeMobileMenu}
							></div>
						)}

						<div
							className={`${
								nav ? 'absolute top-0 left-0 w-full h-screen' : 'hidden lg:flex'
								// inside of navbar mobile
							} bg-transparent z-10 lg:w-auto lg:h-auto lg:flex lg:flex-row lg:items-center lg:bg-transparent`}
						>
							<AiOutlineClose
								onClick={toggleNav}
								size={30}
								className='absolute right-4 top-4 cursor-pointer lg:hidden'
							/>
							<div className='p-4 lg:hidden'>
								<Logo />
							</div>

							<nav className='uppercase lg:flex lg:flex-row lg:items-center lg:justify-start lg:relative lg:right-[20px]'>
								<ul className='flex flex-col p-4 text-gray-800 lg:flex-row lg:ml-auto lg:mr-4'>
									{user?.email ? (
										<div className='flex flex-col lg:gap-1 lg:flex-row'>
											<Link to='/account'>
												<button
													className='uppercase flex items-center justify-center bg-transparent lg:px-6 px-12 py-2 rounded cursor-pointer text-blue-400 lg:text-white text-center lg:mb-0 mb-1 border-2 lg:border-white lg:hover:border-blue-400 lg:hover:text-blue-400 font-bold transition duration-300'
													onClick={closeMobileMenu}
												>
													{user.displayName
														? `${user.displayName}`
														: t('myAccount')}
												</button>
											</Link>
											<button
												onClick={() => {
													handleLogout();
													closeMobileMenu();
												}}
												className='uppercase bg-transparent border-2 lg:border-white lg:px-6 px-12 py-2 lg:mr-1 w-fit lg:mb-0 mb-1 rounded cursor-pointer text-blue-400 lg:text-white lg:hover:border-red-500 lg:hover:text-red-500 font-bold transition duration-300'
											>
												{t('logout')}
											</button>
										</div>
									) : (
										<div className='flex flex-col gap-1 lg:flex-row'>
											<Link to='/login'>
												<button
													className='uppercase bg-transparent border-2 lg:border-white lg:px-6 px-[66px] py-2 rounded cursor-pointer text-blue-400 lg:text-white lg:hover:border-blue-400 lg:hover:text-blue-400 font-bold transition duration-300'
													onClick={closeMobileMenu}
												>
													{t('login')}
												</button>
											</Link>
											<Link to='/signup'>
												<button
													className='uppercase bg-transparent border-2 lg:border-white lg:px-6 px-12 py-2 lg:mb-0 mb-1 mr-1 rounded cursor-pointer text-blue-400 lg:text-white lg:hover:border-green-500 lg:hover:text-green-500 font-bold transition duration-300'
													onClick={closeMobileMenu}
												>
													{t('createAcc')}
												</button>
											</Link>
										</div>
									)}
									<div className='flex flex-col w-fit gap-1 lg:flex-row'>
										<Link
											to='/contact'
											className='bg-transparent border-2 lg:border-white lg:px-6 px-12 py-2 rounded cursor-pointer text-blue-400 lg:text-white text-center lg:hover:border-blue-400 lg:hover:text-blue-400 font-bold transition duration-300'
											onClick={closeMobileMenu}
										>
											{t('contactUs')}
										</Link>
										<Link
											to='/about'
											className='bg-transparent border-2 lg:border-white lg:px-6 px-12 py-2 rounded cursor-pointer text-blue-400 lg:text-white text-center lg:hover:border-blue-400 lg:hover:text-blue-400 font-bold transition duration-300'
											onClick={closeMobileMenu}
										>
											{t('aboutUs')}
										</Link>
									</div>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;

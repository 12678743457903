import { useEffect, useState } from 'react';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';

const GoTopButton = () => {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			// Check if the user has scrolled down enough to show the button
			const isScrolled = window.scrollY > 100;
			setShowButton(isScrolled);
		};

		// Add event listener for scroll events
		window.addEventListener('scroll', handleScroll);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	return (
		<>
			{showButton && (
				<div className='fixed bottom-2 right-5'>
					<BsFillArrowUpCircleFill
						onClick={scrollToTop}
						className='text-blue-400 w-10 h-10 cursor-pointer'
					/>
				</div>
			)}
		</>
	);
};

export default GoTopButton;

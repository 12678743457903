import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from './contexts/SidebarContext';
import { CartContext } from './contexts/CartContext';
import { BsFillCartPlusFill } from 'react-icons/bs';

const Header = () => {
	const [isActive, setIsActive] = useState(false);
	const { isOpen, setIsOpen } = useContext(SidebarContext);
	const { itemAmount } = useContext(CartContext);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
		});
	});

	const displayAmount = () => {
		if (isNaN(itemAmount)) {
			return 'invalid';
		}
		return itemAmount;
	};

	return (
		<header
			className={`${
				isActive ? 'w-[32px]' : 'bg-none'
			}  text-white z-10 transition-all`}
		>
			<div
				onClick={() => setIsOpen(!isOpen)}
				className='cursor-pointer flex flex-col justify-center items-center'
			>
				<BsFillCartPlusFill className='w-[32px] text-4xl text-white hover:text-blue-400' />
				<div className='bg-red-600 text-[12px] w-[18px] h-[18px] text-white rounded md:px-7 px-8 flex justify-center items-center'>
					{displayAmount()}
				</div>
			</div>
		</header>
	);
};

export default Header;

import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrderSubmissionPage = () => {
	const { t } = useTranslation();
	const {
		state: { orderData, cart, invoiceNumber },
	} = useLocation();
	// const [submittingDownload, setSubmittingDownload] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);

	useEffect(() => {
		let total = 0;

		if (cart && cart.length > 0) {
			total = cart.reduce(
				(accumulator, currentItem) =>
					accumulator + (currentItem.initialPrice * currentItem.amount || 0),
				0
			);
		}

		setTotalPrice(total);
	}, [cart]);

	return (
		<div className='container mx-auto p-4 space-y-8'>
			<div className='flex flex-row items-start justify-start'>
				<h1 className='text-3xl font-semibold text-center'>{t('orderh2')}</h1>
				{invoiceNumber && (
					<p className='text-3xl mx-2 font-semibold text-center text-red-600'>
						{invoiceNumber}
					</p>
				)}
			</div>

			{cart && (
				<div className='bg-white p-4 rounded-md shadow-md'>
					<h2 className='text-2xl font-semibold mb-4 text-primary'>
						{t('orderSummary')}
					</h2>
					{cart.length > 0 ? (
						<ul className='space-y-4'>
							{cart.map((item) => (
								<li
									key={item.id}
									className='flex justify-between items-center text-lg py-2 md:py-3'
								>
									<div className='flex flex-col'>
										<p className='text-base md:text-lg lg:text-lg text-slate-900 font-semibold uppercase'>
											{item.name}
											<span className='text-red-600 mx-1 italic'>
												/{item.unit}
											</span>
										</p>
										<p className='text-sm md:text-base lg:text-base font-bold text-slate-600'>
											{t('quantity')} {item.amount} {item.unit}
											<span className='mx-2 text-red-600 font-bold'>
												{item.price !== undefined &&
													`$${item.price.toFixed(2)}`}{' '}
											</span>
										</p>
										<div className='text-sm md:text-sm lg:text-sm text-slate-600'>
											<p>
												Current Price:{' '}
												{item.initialPrice !== undefined &&
													`$${item.initialPrice.toFixed(2)}`}
											</p>{' '}
										</div>
									</div>
								</li>
							))}
							<div className='my-2 border shadow-lg bg-slate-200 w-fit p-2 rounded'>
								<p className='text-lg font-semibold uppercase'>
									{t('totalPrice')}:{' '}
									<span className='text-red-600'>{`$${totalPrice.toFixed(
										2
									)}`}</span>
								</p>
							</div>
						</ul>
					) : (
						<p className='text-gray-600'>No items found in the order.</p>
					)}
				</div>
			)}

			{orderData && (
				<div className='bg-white p-4 gap-x-3 rounded-md shadow-md text-lg'>
					<h2 className='text-2xl font-semibold mb-4 text-primary'>
						{t('userInfo')}
					</h2>
					<p>
						{t('nameLabel')} {orderData.name || 'N/A'}
					</p>
					{orderData.email && (
						<p>
							{t('emailLabel')} {orderData.email}
						</p>
					)}
					<p>
						{t('phone')} {orderData.phoneNumber || 'N/A'}
					</p>
					<p>Address: {orderData.address || 'N/A'}</p>
					<p>
						{orderData.city || 'N/A'}, {orderData.province || 'N/A'}{' '}
						{orderData.postalCode || 'N/A'}
					</p>

					{orderData.note && <p>Note: {orderData.note}</p>}
				</div>
			)}
			<Link to={'/'}>
				<div className='my-4 text-lg font-bold p-2 shadow-lg w-fit rounded-md text-blue-500'>
					{t('home')}
				</div>
			</Link>
		</div>
	);
};

export default OrderSubmissionPage;

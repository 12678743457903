import { useContext, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { CartContext } from './contexts/CartContext';
import { UserAuth } from './contexts/AuthContext';
import { db } from '../firebase';
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { showCartItemAddedNotification } from './CartItemAddedNotification';
import { useTranslation } from 'react-i18next';

const Product = ({ product }) => {
	const { t } = useTranslation();
	const { addToCart, removeFromCart } = useContext(CartContext);
	const { id, image, category, name, unit, price } = product;
	// const [isPopular, setIsPopular] = useState(false);

	// const popularProductIds = useMemo(
	// 	() => [
	// 		'Redpath_Fine_sugar',
	// 		'KingElephant_ricestick_S',
	// 		'KingElephant_ricestick_M',
	// 		'KingElephant_ricestick_XL',
	// 		'Lucky_phoenix_rice',
	// 		'Fortune_cookie',
	// 		'Kraft_pb',
	// 		'Sifto_salt',
	// 		'General_chicken',
	// 		'Chicken_breast_medium_fresh',
	// 		'Chicken_breast_medium_frozen',
	// 		'Chicken_breast_jumbo',
	// 		'Chicken_sliced_1',
	// 		'Chicken_boneless_leg',
	// 		'Beef_slice',
	// 		'Beef_DND_strips',
	// 		'Frozen_meatball_10kg',
	// 		'Pork_ribs',
	// 		'Pork_wonton',
	// 		'Shrimp_21_25',
	// 		'Shrimp_26_30',
	// 		'Shrimp_31_35',
	// 		'Shrimp_31_40',
	// 		'Shrimp_41_50',
	// 		'Shrimp_51_60',
	// 		'Shrimp_61_70',
	// 		'Thailand_White_Shrimp_13_15',
	// 		'Thailand_White_Shrimp_16_20',
	// 		'Thailand_White_Shrimp_21_25',
	// 		'Thailand_White_Shrimp_26_30',
	// 		'Indian_Tiger_Shrimp_13_15',
	// 		'Indian_Tiger_Shrimp_21_25',
	// 		'EC_Shrimp_26_30',
	// 		'IN_Shrimp_26_30',
	// 		'IN_Shrimp_21_25',
	// 		'IN_Shrimp_16_20',
	// 		'Naviks_blacktiger_1315_HLSO',
	// 		'Black_tiger_1315_K226',
	// 		'Paradise_cooked_shrimp',
	// 		'Squid_tube_U5',
	// 		'Tasty_pork_vegetable',
	// 		'Tasty_jumbo_pork_veg',
	// 		'Tasty_jumbo_veg_chicken',
	// 		'Springroll_pastry',
	// 		'Aji_umami',
	// 		'Knorr_chicken_broth_powder',
	// 		'Egg_roll',
	// 		'Cynthia_steam_bun',
	// 		'Yupin_bamboo_slices',
	// 		'Golden_canola_oil',
	// 		'Lion_white_vinegar',
	// 		'Dark_Soysauce',
	// 		'Light_Soysauce',
	// 		'LKK_soy_sauce',
	// 		'Wing_hot_chili_sauce',
	// 		'Wing_plum_sauce',
	// 		'Sriracha_hotchili_sauce',
	// 		'JHL_instant_sourshrimp_paste',
	// 		'JHL_crabpaste_soyabean_oil',
	// 		'Pantai_chilipaste_soyabean_oil',
	// 		'Maeploy_redcurry_paste',
	// 		'Wings_eggroll_sauce',
	// 		'Kikkoman_soysauce',
	// 		'Tomyum_instant_hotsour_paste',
	// 		'Tomyum_instant_paste',
	// 	],
	// 	[]
	// );

	// useEffect(() => {
	// 	// Check if the product's ID is within the array of popular product IDs
	// 	const isProductPopular = popularProductIds.includes(id);
	// 	setIsPopular(isProductPopular);
	// }, [id, popularProductIds]);

	const [like, setLike] = useState(false);
	const [saved, setSaved] = useState(false);
	const [inCart, setInCart] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = () => {
		// When the image has finished loading, set the imageLoaded state to true
		setImageLoaded(true);
	};

	const { user } = UserAuth();
	const productID = doc(db, 'users', `${user?.email}`);

	const saveProduct = async () => {
		if (user?.email) {
			setLike(!like);
			setSaved(true);
			await updateSavedProducts();
		} else {
			alert('Please log in to save a product');
		}
	};

	const updateSavedProducts = useCallback(async () => {
		await updateDoc(productID, {
			savedProducts: arrayUnion({
				id: product.id,
				name: product.name,
				image: product.image,
				unit: product.unit,
				price: product.price,
			}),
		});
	}, [product, productID]);

	useEffect(() => {
		const fetchLikedStatus = async () => {
			if (user?.email) {
				const docSnap = await getDoc(productID);
				if (docSnap.exists()) {
					const savedProducts = docSnap.data().savedProducts || [];
					const isLiked = savedProducts.some(
						(savedProduct) => savedProduct.id === product.id
					);
					setLike(isLiked);
				}
			}
		};
		fetchLikedStatus();
	}, [user, product.id, productID]);

	const handleAddToCart = () => {
		addToCart(product, id);
		setInCart(true);
		showCartItemAddedNotification(product.name);
	};

	const handleRemoveFromCart = () => {
		removeFromCart(id);
		setInCart(false);
	};

	return (
		<div>
			<div className='border border-[#e4e4e4] mb-4 relative overflow-hidden group transition rounded-lg'>
				<div className='border shadow-lg rounded-lg hover:scale-105 duration-300'>
					<Link to={`/product/${id}`}>
						<div
							className={`w-full h-[200px] rounded-t-lg overflow-hidden transition-opacity ${
								imageLoaded ? 'opacity-100' : 'opacity-0'
							}`}
						>
							<img
								src={image}
								alt={image.id}
								className='w-full h-full object-cover'
								loading='lazy'
								onLoad={handleImageLoad}
							/>
						</div>
					</Link>

					<p onClick={saveProduct}>
						{like ? (
							<FaHeart className='absolute top-4 left-4 text-red-600 cursor-pointer' />
						) : (
							<FaRegHeart className='absolute top-4 left-4 text-red-600 cursor-pointer' />
						)}
					</p>
				</div>

				<div className='absolute top-0 right-0  p-2 flex flex-col items-center justify-center gap-y-2 group-hover:right-5 group-hover:opacity-100 opacity-0 transition-all duration-300'>
					<button
						disabled={inCart}
						onClick={inCart ? handleRemoveFromCart : handleAddToCart}
						className={`  text-white  ${
							inCart ? 'opacity-50 cursor-not-allowed' : ''
						}`}
					>
						<div className='flex justify-center items-center text-white w-12 h-12 bg-red-500'>
							<BsPlus className='text-3xl' />
						</div>
					</button>
				</div>
			</div>
			<div className='flex'>
				{/* {isPopular && (
					<span className='bg-red-600 text-white p-1 rounded-lg shadow-md italic text-sm md:text-base lg:text-base xl:text-base'>
						Most Popular
					</span>
				)} */}
				<p className='text-base capitalize text-gray-500'>{t(category)}</p>
			</div>

			<div>
				<div className='flex md:flex-row lg:flex-row xl:flex-row flex-col justify-between my-2'>
					<p className='text-lg capitalize font-bold bg-blue-600 text-white p-2 rounded-md'>
						{price !== undefined && `$${price}`}
						<span className='text-lg font-semibold text-white'>/{unit}</span>
					</p>
				</div>

				<Link to={`/product/${id}`}>
					<h2 className='font-semibold mb-1'>{name}</h2>
				</Link>
			</div>
		</div>
	);
};

export default Product;

import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className='bg-gray-800 text-gray-300'>
			<div className='container mx-auto px-8 py-10 flex flex-col lg:flex-row items-center justify-between'>
				<div className='w-full lg:w-1/3 lg:mr-16 mb-6 lg:mb-0'>
					<h2 className='text-xl font-semibold uppercase mb-4'>
						{t('storeHours')}
					</h2>
					<ul className='list-none space-y-2'>
						<li>{t('monFriHours')}</li>
						<li>{t('satSunHours')}</li>
					</ul>
				</div>
				<div className='w-full lg:w-1/3 lg:mr-16 mb-6 lg:mb-0'>
					<h2 className='text-xl font-semibold uppercase mb-4'>
						{t('deliveryHours')}
					</h2>
					<ul className='list-none space-y-2'>
						<li>{t('monFriDeliveryHours')}</li>
						<li>{t('satSunDeliveryHours')}</li>
					</ul>
				</div>
				<div className='w-full lg:w-1/2'>
					<h2 className='text-xl font-semibold uppercase mb-4'>
						{t('contactUs')}
					</h2>
					<ul className='list-none space-y-4'>
						<li>
							<a
								href='https://www.google.com/maps/search/?api=1&query=Oriental+Trading'
								target='_blank'
								rel='noopener noreferrer'
								className='text-white hover:underline'
							>
								{t('address')}
							</a>
						</li>
						<li>
							<a
								href='mailto:sales@orientaltradingca.com'
								className='text-white hover:underline'
							>
								sales@orientaltradingca.com
							</a>
						</li>
						<li>
							{t('webInquiry')} <a href='tel:4389853828'>438-985-3828</a>
						</li>
						<li>
							{t('ProductsPrice')} <a href='tel:5145028899'>514-502-8899</a>
						</li>
					</ul>
				</div>
			</div>
			<div className=' bg-gray-700 p-3 text-sm text-center text-gray-300'>
				<span className='mr-2'>© {new Date().getFullYear()}</span>
				commerce-oriental.ca
			</div>
		</footer>
	);
};

export default Footer;

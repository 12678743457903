import Truck from '../truckHero.png';
import { useTranslation } from 'react-i18next';
const Hero = () => {
	const { t } = useTranslation();

	return (
		<div className='h-[550px] w-full'>
			<div className='w-full h-full'>
				{/* Overlay */}
				<div className='absolute w-full h-[550px] bg-gradient-to-r from-black text-gray-200'>
					<h1 className='px-4 text-4xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-7xl font-bold absolute top-[45%]'>
						<span className='text-blue-400'>{t('food')} </span>
					</h1>
					<h1 className='px-4 text-4xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-7xl font-bold absolute top-[52%] left-[5%] lg:top-[56%] lg:left-[5%] text-center'>
						<span className='text-blue-600'>{t('product')} </span>
						{t('supplier')}
					</h1>
				</div>
				<img className='w-full h-full object-cover' src={Truck} alt='hero' />
			</div>
		</div>
	);
};

export default Hero;

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useEffect, useState } from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const pageNumbers = Array.from(
		{ length: Math.ceil(totalItems / itemsPerPage) },
		(_, i) => i + 1
	);

	const handlePageClick = (number, e) => {
		e.preventDefault();
		paginate(number);
	};

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const maxDisplayedPages = windowWidth > 640 ? 7 : 5; // Maximum number of page buttons based on screen width

	const getPageNumbersToDisplay = () => {
		if (pageNumbers.length <= maxDisplayedPages) {
			return pageNumbers;
		}

		const halfMaxDisplayedPages = Math.floor(maxDisplayedPages / 2);

		if (currentPage <= halfMaxDisplayedPages) {
			return [
				...pageNumbers.slice(0, maxDisplayedPages - 1),
				'...',
				pageNumbers[pageNumbers.length - 1],
			];
		} else if (currentPage >= pageNumbers.length - halfMaxDisplayedPages) {
			return [
				pageNumbers[0],
				'...',
				...pageNumbers.slice(-maxDisplayedPages + 1),
			];
		} else {
			const startIndex = currentPage - halfMaxDisplayedPages - 1;
			const endIndex = currentPage + halfMaxDisplayedPages;
			return [
				pageNumbers[0],
				'...',
				...pageNumbers.slice(startIndex, endIndex),
				'...',
				pageNumbers[pageNumbers.length - 1],
			];
		}
	};

	return (
		<div className='flex flex-col sm:flex-row items-center justify-center my-5 bg-gray-100 p-2 sm:p-4 rounded-md'>
			<button
				className={`
          p-2 font-bold border border-blue-400 text-blue-400 rounded-md mx-1 mb-1
          bg-white hover:bg-gray-200 focus:outline-none focus:ring focus:border-blue-500
          ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:border-blue-500'}
        `}
				onClick={(e) => handlePageClick(currentPage - 1, e)}
				disabled={currentPage === 1}
			>
				<IoIosArrowBack size='1.5em' />
			</button>

			<div
				className='flex flex-wrap gap-1 justify-center'
				style={{ overflowX: 'auto', flex: 1 }}
			>
				{getPageNumbersToDisplay().map((number, index) => (
					<button
						key={index}
						className={`
              p-2 font-bold border border-blue-400 text-white rounded-md mx-1 mb-1
              ${
								number === currentPage
									? 'bg-blue-500 border-2 border-sky-300 text-sky-300'
									: 'bg-blue-500 hover:bg-blue-600'
							}
            `}
						onClick={(e) => handlePageClick(number, e)}
						disabled={number === '...'}
						style={{ minWidth: '40px' }} // Set a fixed width for the buttons
					>
						{number}
					</button>
				))}
			</div>

			<button
				className={`
          p-2 font-bold border border-blue-400 text-blue-400 rounded-md mx-1 mb-1
          bg-white hover:bg-gray-200 focus:outline-none focus:ring focus:border-blue-400
          ${
						currentPage === Math.ceil(totalItems / itemsPerPage)
							? 'cursor-not-allowed'
							: 'hover:border-blue-500'
					}
        `}
				onClick={(e) => handlePageClick(currentPage + 1, e)}
				disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
			>
				<IoIosArrowForward size='1.5em' />
			</button>
		</div>
	);
};

export default Pagination;

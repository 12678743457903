import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './contexts/AuthContext';
import { useTranslation } from 'react-i18next';
const Login = () => {
	const contentRef = useRef();
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [resetEmailSent, setResetEmailSent] = useState(false);
	const { logIn, resetPassword } = UserAuth();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!email || !password) {
			setErrorMessage('Please fill in both email and password fields.');
			return;
		}
		try {
			await logIn(email, password);
			navigate('/');
		} catch (error) {
			if (error.code === 'auth/user-not-found') {
				setErrorMessage('The provided email address is not registered');
			} else {
				setErrorMessage('An error occured during sign-in. Please try again.');
				console.log(error);
			}
		}
	};

	const handleForgotPassword = async () => {
		if (!email) {
			setErrorMessage('Please enter your email to reset the password.');
			return;
		}

		try {
			await resetPassword(email);
			setResetEmailSent(true);
			setErrorMessage('Password reset email sent. Please check your email.');

			// Clear the message after 3 seconds
			setTimeout(() => {
				setResetEmailSent(false);
				setErrorMessage('');
			}, 3000);
		} catch (error) {
			setErrorMessage('Error sending password reset email. Please try again.');
			console.error('Error sending password reset email:', error);
		}
	};

	useEffect(() => {
		// Scroll to the content section after the component has mounted
		if (contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<div className='w-full h-screen bg-black/90' ref={contentRef}>
			<div className='w-full px-4 py-24 z-50'>
				<div className='max-w-[450px] h-[600px] mx-auto bg-white rounded-lg text-black'>
					<div className='max-w-[320px] mx-auto py-16'>
						<h1 className='text-3xl text-purple-800 font-bold'>
							{t('welcomeBack')}
						</h1>

						<form onSubmit={handleSubmit} className='w-full flex flex-col py-4'>
							<input
								onChange={(e) => setEmail(e.target.value)}
								className='p-3 my-2 bg-slate-200 rounded'
								type='email'
								placeholder={t('emailLabel')}
								autoComplete='email'
							/>
							<input
								onChange={(e) => setPassword(e.target.value)}
								className='p-3 my-2 bg-slate-200 rounded'
								type='password'
								placeholder={t('password')}
								autoComplete='current-password'
							/>
							<button className='bg-gradient-to-r from-purple-500 to-purple-800 text-white py-3 my-6 rounded font-bold'>
								{t('LogIn')}
							</button>

							<p className='py-1'>
								<span className='text-gray-600'>{t('newtoCO')}</span>{' '}
								<Link to='/signup' className='text-purple-500'>
									{t('signup')}
								</Link>
							</p>

							<p
								className='font-bold text-gray-600 cursor-pointer underline'
								onClick={handleForgotPassword}
							>
								{t('forgotPassword')}
							</p>
						</form>
						{errorMessage && <div className='text-red-500'>{errorMessage}</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;

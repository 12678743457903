import { useContext, useState } from 'react';
import { ProductContext } from './contexts/ProductContext';
import {
	PDFDownloadLink,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from '@react-pdf/renderer';

const InventoryList = () => {
	const { products } = useContext(ProductContext);
	const [pdfVisible, setPdfVisible] = useState(false);

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			backgroundColor: '#FFFFFF',
			padding: 20,
		},
		productContainer: {
			marginBottom: 20,
			borderBottom: '1pt solid #CCCCCC',
			paddingBottom: 10,
		},
		productName: {
			fontWeight: 'bold',
			marginBottom: 5,
			fontSize: 16,
		},
		productPrice: {
			fontSize: 14,
		},
		productImage: {
			width: 100,
			height: 100,
			objectFit: 'cover',
		},
	});

	const handleTogglePdf = () => {
		setPdfVisible(!pdfVisible);
	};

	const ProductList = () => (
		<Document>
			<Page size='A4' style={styles.page}>
				<View>
					{products.map((product) => (
						<View key={product.id} style={styles.productContainer}>
							<Text style={styles.productName}>{product.name}</Text>
							<Image src={product.image} style={styles.productImage} />
							<Text style={styles.productPrice}>
								${product.price}/{product.unit}
							</Text>
						</View>
					))}
				</View>
			</Page>
		</Document>
	);

	if (!products || products.length === 0) {
		return (
			<div className='container mx-auto'>
				<p>No products available</p>
			</div>
		);
	}

	return (
		<div className='container mx-auto'>
			<div className='mb-4'>
				<button
					onClick={handleTogglePdf}
					className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
				>
					{pdfVisible ? 'Hide PDF' : 'Show PDF'}
				</button>
				{pdfVisible && (
					<PDFDownloadLink
						document={<ProductList />}
						fileName='product_list.pdf'
					>
						{({ loading }) => (
							<button
								className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-3'
								disabled={loading}
							>
								{loading ? 'Loading PDF...' : 'Download PDF'}
							</button>
						)}
					</PDFDownloadLink>
				)}
			</div>
			<div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0 px-3'>
				{products.map((product) => (
					<div
						key={product.id}
						className='flex flex-col justify-between text-black border-black p-2 border-2'
					>
						<p className='font-bold'>{product.name}</p>
						<p className='text-gray-500 text-sm'>{product.category}</p>
						<img
							src={product.image}
							alt={product.name}
							className='w-full h-auto'
						/>
						<p className='text-red-600 font-bold'>
							${product.price}
							<span className='text-red-600'>/{product.unit}</span>
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default InventoryList;

import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ProductContext } from './contexts/ProductContext';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
	const { t } = useTranslation();
	const [value, setValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [focusedIndex, setFocusedIndex] = useState(-1); // Index of the focused suggestion
	const [loading, setLoading] = useState(false); // Loading state
	const navigate = useNavigate();
	const { products } = useContext(ProductContext);
	const suggestionsRef = useRef(null);
	const inputRef = useRef(null);

	useEffect(() => {
		setLoading(true); // Set loading to true when searching

		// Filter products based on the search value
		const filteredProducts = products.filter(
			(product) =>
				product.name.toLowerCase().includes(value.toLowerCase()) ||
				product.category.toLowerCase().includes(value.toLowerCase())
		);

		// Limit the number of suggestions
		const limitedSuggestions = filteredProducts.slice(0, 12);

		// Simulate asynchronous data loading with a timeout (remove this in your real application)
		setTimeout(() => {
			// Set the suggestions based on the filtered products
			setSuggestions(limitedSuggestions);
			// Reset focused index when suggestions change
			setFocusedIndex(-1);
			setLoading(false); // Set loading to false when suggestions are ready
		}, 1000); // Replace 1000 with the actual loading time in milliseconds in your application
	}, [value, products]);

	const handleSearch = () => {
		if (value) {
			navigate(`/search-result/${value}`);
		}
	};

	const handleSuggestionClick = () => {
		setValue('');
		// Clear suggestions after clicking on an item
		setSuggestions([]);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
			// Clear the search input after navigating
			setValue('');
		} else if (event.key === 'ArrowDown') {
			// Move focus down when arrow down key is pressed
			setFocusedIndex((prevIndex) =>
				prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
			);
		} else if (event.key === 'ArrowUp') {
			// Move focus up when arrow up key is pressed
			setFocusedIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : prevIndex
			);
		}
	};

	const handleSearchBarClick = () => {
		// When the search bar is clicked, set focus on the input element
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<div className='relative flex items-center mx-2 w-[150px] sm:w-[200px] md:w-[200px] lg:w-[250px]'>
			<input
				ref={inputRef}
				type='search'
				className='w-full focus:outline-none p-1 pl-2 text-center bg-slate-100 rounded-full border border-gray-300 transition duration-300 ease-in-out'
				placeholder={t('searchPlaceholder')}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				onKeyPress={handleKeyPress}
				onKeyDown={handleKeyPress} // Listen for arrow keys
				onClick={handleSearchBarClick}
			/>

			{loading && (
				<div className='absolute top-full left-0 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-md z-10'>
					<div className='p-2 flex justify-center items-center text-blue-400'>
						<AiOutlineLoading3Quarters className='animate-spin' size={24} />
					</div>
				</div>
			)}

			{!loading && value && suggestions.length > 0 && (
				<div
					ref={suggestionsRef}
					className='absolute top-full left-0 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-md z-10'
				>
					{suggestions.map((product, index) => (
						<Link
							key={product.id}
							to={`/product/${product.id}`}
							className={`block p-2 border-b border-gray-200 hover:bg-gray-100 ${
								index === focusedIndex ? 'bg-gray-200' : ''
							}`}
							onClick={() => handleSuggestionClick(product.name)}
						>
							<div className='flex flex-col'>
								<span className='text-gray-800 font-semibold'>
									{product.name}
								</span>
								<span className='text-gray-500 text-sm'>
									{product.category}
								</span>
							</div>
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

export default SearchBar;

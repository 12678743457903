const resources = {
	/// ENGLISH
	en: {
		translation: {
			//about us
			aboutUs: 'About Us',
			aboutSection1: {
				title: 'Oriental Trading Canada!',
				text: `Also recognized as Commerce Oriental: Our commitment lies in delivering superior quality products and services to our esteemed customers. Our primary goal is to cater to your requirements and surpass your expectations.`,
			},
			aboutSection2: {
				title: 'Our Products',
				text: `With years of experience in the industry, we take pride in offering a wide range of products, including frozen seafood, frozen meat, pantry essentials, and more. We source our products from trusted suppliers to ensure freshness and quality.`,
			},
			aboutSection3: {
				title: 'Customer Satisfaction',
				text: `At Oriental Trading Canada, customer satisfaction is our top priority. We strive to provide excellent customer service and deliver products that meet the highest standards.`,
			},
			aboutSection4: {
				title: 'Contact Us',
				text: `If you have any questions or would like to learn more about our company, please don't hesitate to contact us. We look forward to serving you and building a lasting relationship.`,
			},
			//footer
			storeHours: 'Store Hours',
			monFriHours: 'Monday - Friday: 9:30am - 4pm',
			satSunHours: 'Saturday - Sunday: Closed',
			deliveryHours: 'Delivery Hours',
			monFriDeliveryHours: 'Monday - Friday: 10am - 9pm',
			satSunDeliveryHours: 'Saturday - Sunday: 10am - 9pm',
			contactUs: 'Contact Us',
			address: '190 rue Joseph-Carrier, unit 125 Vaudreuil-Dorion, QC J7V 3V1',
			webInquiry: 'Web Inquiry:',
			ProductsPrice: 'Products & Price:',
			// contact us
			contactFormDescription:
				"Please fill out the form below to get in touch with us. We'll get back to you as soon as possible.",
			nameLabel: 'Name/Company Name:',
			emailLabel: 'Email Address:',
			messageLabel: 'Message',
			submitButton: 'Submit',
			//hero
			food: 'food',
			product: 'product',
			supplier: 'supplier in Montréal',
			//searchbar
			searchPlaceholder: 'Search me!',
			//navbar
			createAcc: 'CREATE ACC',
			login: 'LOG IN',
			logout: 'LOG OUT',
			myAccount: 'MY ACC',
			//signup page
			createacc: 'Create an account',
			password: 'Password',
			displayName: 'Display Name',
			signup: 'Sign Up',
			signin: 'Sign In',
			alreadyClient: 'Already a client to Commerce Oriental?',
			//login page
			welcomeBack: 'Welcome back',
			LogIn: 'Log In',
			newtoCO: 'New to Commerce Oriental?',
			forgotPassword: 'Forgot Password?',
			//sidebar
			checkoutBtn: 'Checkout',
			shoppingBag: 'Shopping bag',
			invalidBag: 'Invalid Bag',
			//account
			likedProduct: 'Liked Products',
			//
			special: 'Special',
			//
			resetPassword: 'Reset Password',
			saveitemP: 'Save item to view them',
			noSpecial: 'No special of the week at the moment!',
			//checkoutpage
			quantity: 'Quantity',
			userInfo: 'User Info',
			avis: 'Important Notice',
			notice:
				'Tax is applicable on kitchen supplies; hence, the displayed price does not encompass tax. Food items are exempt from taxation and are currently listed at zero due to frequent market fluctuations. Sam will promptly reach out to provide updated food pricing information.',
			submitBtn: 'Submit Order',
			phone: 'Phone Number:',
			optional: '(Optional)',
			//orderSubmission page
			orderh2: 'Order Submission',
			orderSummary: 'Order Summary',
			downloadInvoice: 'Download Invoice',
			backHome: 'Back to home',
			invoiceP: 'Please ensure you download your invoice to avoid losing it.',
			//HOME
			categoryUl: 'Categories',
			viewCategory: 'View categories list',
			//category
			'frozen seafood': 'frozen seafood',
			'frozen meat': 'frozen meat',
			'frozen goods': 'frozen goods',
			condiment: 'condiment',
			'sauce & oil': 'sauce & oil',
			'grains & flour': 'grains & flour',
			'Dry goods': 'Dry goods',
			// oil: 'oil',
			'kitchen supplies': 'kitchen supplies',
			Seasonings: 'Seasonings',
			'instant food': 'instant food',
			'peppers & bean pastes': 'peppers & bean pastes',
			//Cart Item notification
			productAdded: 'added to the cart!',
			//product
			addToCart: 'Add to cart',
			productNotFound: 'Product not found',
			productP: 'Sorry, the product does not exist!',
			//Thank you
			thankyouh2: 'Thank you for reaching out!',
			thankyouP:
				'Your message has been received. Our team will review your inquiry shortly. For further assistance, feel free to contact us directly.',
			// MONTHS //
			september: 'september',
			october: 'october',
			november: 'november',
			december: 'december',
			january: 'january',
			february: 'february',
			march: 'march',
			april: 'april',
			may: 'may',
			june: 'june',
			july: 'july',
			august: 'august',
			//special of the week
			pickup: 'For Pick Up Only',
			googlemap: 'View on Google Maps',
			call: 'Please call for further questions',
			///inventory///
			dateRange: 'Select date range',
			filterCategory: 'Filter by category',
			filterSupplier: 'Filter by supplier',
			searchItem: 'Search Items...',
			select: 'Select...',
			pdf: 'Download PDF',
			//language toggle
			selectLanguage: 'Select language',
			//h2 home//
			noticeHome:
				'A price listed as zero denotes that the price is currently unavailable or not provided at this time',
		},
	},
	/// FRENCH
	fr: {
		translation: {
			//about
			aboutUs: 'À propos de nous',
			aboutSection1: {
				title: 'Commerce Oriental Canada !',
				text: `Également reconnu sous le nom de Commerce Oriental : Notre engagement réside dans la fourniture de produits et services de qualité supérieure à nos clients estimés. Notre objectif principal est de répondre à vos besoins et de dépasser vos attentes.`,
			},
			aboutSection2: {
				title: 'Nos Produits',
				text: `Avec des années d'expérience dans l'industrie, nous sommes fiers de proposer une large gamme de produits, notamment des fruits de mer surgelés, de la viande surgelée, des produits d'épicerie et bien plus encore. Nous sourçons nos produits auprès de fournisseurs de confiance pour garantir fraîcheur et qualité.`,
			},
			aboutSection3: {
				title: 'Satisfaction Client',
				text: `Chez Commerce Oriental Canada, la satisfaction client est notre priorité. Nous nous efforçons de fournir un excellent service client et de livrer des produits répondant aux normes les plus élevées.`,
			},
			aboutSection4: {
				title: 'Contactez-nous',
				text: `Si vous avez des questions ou si vous souhaitez en savoir plus sur notre entreprise, n'hésitez pas à nous contacter. Nous sommes impatients de vous servir et de construire une relation durable.`,
			},
			//footer
			storeHours: "Heures d'ouverture",
			monFriHours: 'Lundi - Vendredi: 9h30 - 16h',
			satSunHours: 'Samedi - Dimanche: Fermé',
			deliveryHours: 'Heures de livraison',
			monFriDeliveryHours: 'Lundi - Vendredi: 10h - 21h',
			satSunDeliveryHours: 'Samedi - Dimanche: 10h - 21h',
			contactUs: 'Contactez-nous',
			address: '190 rue Joseph-Carrier, unité 125 Vaudreuil-Dorion, QC J7V 3V1',
			webInquiry: 'Demande Web:',
			ProductsPrice: 'Produits et Prix:',
			//contact us
			contactFormDescription:
				'Veuillez remplir le formulaire ci-dessous pour nous contacter. Nous vous répondrons dès que possible.',
			nameLabel: "Nom/nom de l'entreprise:",
			emailLabel: 'Adresse e-mail:',
			messageLabel: 'Message',
			submitButton: 'Envoyer',
			//hero
			food: 'Nourriture',
			product: 'Fournisseur',
			supplier: 'de produits à Montréal',
			//searchbar
			searchPlaceholder: 'Cherchez-moi!',
			//navbar
			createAcc: 'CRÉER',
			login: 'CONNECTÉ',
			logout: 'DÉCONNECTÉ',
			myAccount: 'MON COMPTE',
			//signup page
			createacc: 'Créer un compte',
			password: 'Mot de passe',
			displayName: 'Afficher un nom',
			signin: 'Connecter',
			signup: 'Créer un compte',
			alreadyClient: 'Déja un membre de Commerce Oriental?',
			//login page
			welcomeBack: 'Content de te revoir',
			LogIn: 'Se connecter',
			newtoCO: 'Vous êtes nouveau dans le Commerce Oriental?',
			forgotPassword: 'Mot de passe oublié?',
			//sidebar
			checkoutBtn: 'Vérifier',
			shoppingBag: 'Pannier',
			invalidBag: 'Panier Invalide',
			//account
			likedProduct: 'Produits aimés',
			special: 'Spéciale',
			resetPassword: 'Réinitialiser mot de passe',
			saveitemP: 'Sauver les produits pour les afficher',
			noSpecial: 'Pas de spéciale de la semaine pour le moment!',
			//checkoutpage
			quantity: 'Quantité',
			userInfo: 'Information utilisateur',
			avis: 'Avis important',
			notice:
				'Les ustensiles de cuisine sont soumis à une taxe ; par conséquent, le prix actuellement affiché ne comprend pas cette taxe. Les produits alimentaires sont exonérés de taxe et sont actuellement fixés à zéro en raison des fluctuations fréquentes du marché. Sam prendra contact avec vous dès que possible pour vous fournir les informations de tarification actualisées des produits alimentaires.',
			submitBtn: 'Soumettre la commande',
			phone: 'Numéro téléphone:',
			optional: '(Facultatif)',
			//orderSubmission page
			orderh2: 'Soumission de Commande',
			orderSummary: 'Résumé de la Commande',
			downloadInvoice: 'Télécharger la facture',
			backHome: 'Retour à la page accueil',
			invoiceP:
				'Veuillez vous assurer de télécharger votre facture pour éviter de la perdre.',
			//HOME
			categoryUl: 'Catégories',
			viewCategory: 'Voir la liste des catégories',
			//category
			'frozen seafood': 'fruits de mer surgelés',
			'frozen meat': 'viande surgelée',
			'frozen goods': 'produits surgelés',
			condiment: 'condiment',
			'sauce & oil': 'sauce & huile',
			'grains & flour': 'grains & farine',
			'Dry goods': 'Produits secs',
			// oil: 'huile',
			'kitchen supplies': 'équipements de cuisine',
			Seasonings: 'Assaisonnements',
			'instant food': 'nourriture instantanée',
			'peppers & bean pastes': 'poivres & pâtes de haricots',
			//Cart Item notification
			productAdded: 'Ajouté dans le pannier!',
			//product
			addToCart: 'Ajouté dans le pannier',
			productNotFound: 'Produit pas trouvé',
			productP:
				'Nous nous excusons, mais le produit que vous recherchez existe pas.',
			//Thank you
			thankyouh2: 'Merci de nous avoir contactés!',
			thankyouP:
				'Votre message a été reçu. Notre équipe examinera votre demande sous peu. Pour toute assistance supplémentaire, ne hésitez pas à nous contacter directement.',
			// MONTHS //
			september: 'septembre',
			october: 'octobre',
			november: 'novembre',
			december: 'décembre',
			january: 'janvier',
			february: 'février',
			march: 'mars',
			april: 'avril',
			may: 'mai',
			june: 'juin',
			july: 'juillet',
			august: 'août',
			//special of the week
			pickup: 'Pour Ramassage Seulement',
			googlemap: 'Voir sur Google Maps',
			call: "Veuillez appeler pour d'autres questions",
			///inventory///
			dateRange: 'Choisir la date',
			filterCategory: 'Recherche par catégorie',
			filterSupplier: 'Recherche par livreur',
			searchItem: 'Recherche un produit',
			select: 'Choisir...',
			pdf: 'PDF',
			//language toggle
			selectLanguage: 'Choisir la langue',
			//h2 home//
			noticeHome:
				'Un prix affiché à zéro indique que le prix est actuellement pas disponible ou non fourni pour le moment.',
		},
	},
	/// CANTONESE CHINESE SIMPLIFIED
	zh: {
		translation: {
			//about us
			aboutUs: '关于我们',
			aboutSection1: {
				title: 'Oriental Trading Canada!',
				text: `都被公認為东方商務：我哋嘅承諾在於為我哋尊敬嘅客戶提供優質嘅產品同服務。 我哋嘅主要目標係滿足您的要求並超越您的期望.`,
			},
			aboutSection2: {
				title: '我哋嘅產品',
				text: `憑藉多年嘅行業經驗，我哋以提供廣泛嘅產品而自豪，包括冷凍海鮮、冷凍肉類、食品士多房必需品等。 我哋由值得信賴嘅供應商处採購產品，以確保新鮮度同質素.`,
			},
			aboutSection3: {
				title: '客戶滿意度',
				text: `在Oriental Trading Canada, 客戶滿意度係我哋嘅首要任務。 我哋努力提供卓越嘅客戶服務，並提供符合最高標準嘅產品.`,
			},
			aboutSection4: {
				title: '聯繫我哋',
				text: `如果你有任何問題或想了解更多關於我哋公司嘅信息，請隨時與我哋聯繫。 我哋期待為您服務並建立持久嘅關係.`,
			},
			//footer
			storeHours: '營業時間',
			monFriHours: '星期一 - 星期五: 9:30am - 4pm',
			satSunHours: '星期六 - 星期日: 閉',
			deliveryHours: '交貨時間',
			monFriDeliveryHours: '星期一 - 星期五: 10am - 9pm',
			satSunDeliveryHours: '周六 - 星期日: 10am - 9pm',
			contactUs: '聯繫我哋',
			address: '190 rue Joseph-Carrier, unit 125 Vaudreuil-Dorion, QC J7V 3V1',
			webInquiry: '網站查詢:',
			ProductsPrice: '產品及價格:',
			// contact us
			contactFormDescription: '請填寫以下表格與我哋聯繫。 我們會盡快回覆您.',
			nameLabel: '姓名/公司名稱:',
			emailLabel: '電子郵件地址:',
			messageLabel: '消息',
			submitButton: '提交',
			//hero
			food: '食物',
			product: '產品',
			supplier: '供應商（公司)（蒙特利爾)',
			//searchbar
			searchPlaceholder: '搜尋!',
			//navbar
			createAcc: '創建賬戶',
			login: '登錄',
			logout: '註銷',
			myAccount: '我嘅账户',
			//signup page
			createacc: '創建一個帳戶',
			password: '密碼',
			displayName: '顯示名稱',
			signup: '登記',
			signin: '登錄',
			alreadyClient: '已經係以下客戶 Commerce Oriental?',
			//login page
			welcomeBack: '歡迎返嚟',
			LogIn: '登錄',
			newtoCO: '新用戶 Commerce Oriental?',
			forgotPassword: '忘記密碼?',
			//sidebar
			checkoutBtn: '收款处',
			shoppingBag: '購物袋',
			invalidBag: '無效行李',
			//account
			likedProduct: '鍾意嘅產品',
			special: '特殊',
			resetPassword: '重置密碼',
			saveitemP: '保存項目以查看它們',
			noSpecial: '目前本周冇特別之處!',
			//checkoutpage
			quantity: '數量',
			userInfo: '用戶信息',
			avis: '重要通知',
			notice:
				'廚房用品需繳稅; 因此，顯示嘅價錢唔包括稅費。 由於市場波動頻繁，食品免稅，目前上市為零。 Sam將及時與您聯繫, 提供最新嘅食品定價信息。',
			submitBtn: '提交訂單',
			phone: '電話號碼:',
			optional: '(自選)',
			//orderSubmission page
			orderh2: '訂單提交',
			orderSummary: '訂單摘要',
			downloadInvoice: '下載發票',
			backHome: '家',
			invoiceP: '請確保你下載了發票，以免賴發票.',
			//HOME
			categoryUl: '類別',
			viewCategory: '查看類別列表',
			//category
			'frozen seafood': '冷凍海鮮',
			'frozen meat': '凍肉',
			'frozen goods': '冷凍品',
			condiment: '調味品',
			'sauce & oil': '醬 & 油',
			'grains & flour': '穀物 & 麵粉',
			'Dry goods': '干货',
			// oil: 'oil',
			'kitchen supplies': '廚房用品',
			Seasonings: '调料',
			'instant food': '方便食品',
			'peppers & bean pastes': '辣椒 & 豆沙',
			//Cart Item notification
			productAdded: '已加入購物車!',
			//product
			addToCart: '加入購物車',
			productNotFound: '未搵到產品',
			productP: '对唔住，該產品唔存在!',
			//Thank you
			thankyouh2: '感謝您伸出援手!',
			thankyouP:
				'您的消息已收到。 我哋嘅團隊將盡快審核您的詢問。 如需進一步幫助，請隨時直接與我們聯繫.',
			// MONTHS //
			september: '9月',
			october: '10月',
			november: '11月',
			december: '12月',
			january: '1月',
			february: '2月',
			march: '3月',
			april: '4月',
			may: '5月',
			june: '6月',
			july: '7月',
			august: '8月',
			//special of the week
			pickup: '僅限取貨',
			googlemap: '在穀謌地圖上查看',
			call: '如有其他問題，請致電',
			///inventory///
			dateRange: '選擇日期範圍',
			filterCategory: '按類別篩選',
			filterSupplier: '按供應商篩選',
			searchItem: '搜索項目',
			select: '選擇',
			pdf: '下載PDF',
			///language toggle
			selectLanguage: '選擇語言',
			//h2 home//
			noticeHome: '列為零嘅價錢表示該價格當前不可用或目前未提供',
		},
	},
};

export default resources;

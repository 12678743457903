import Special from './Special';
import specialOffers from './SpecialData';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
// import { RiMapPinLine } from 'react-icons/ri';

const SpecialOfTheWeek = () => {
	const { t } = useTranslation();

	return (
		<div className='container mx-auto px-4 sm:px-6 lg:px-8 py-8'>
			<div className='mb-4 flex items-center justify-start text-lg font-bold'>
				<Link to='/' className='flex items-center text-blue-500 p-2 rounded-md'>
					<FiArrowLeft className='mr-2' />
					{t('backHome')}
				</Link>
			</div>
			<div>
				{/* Render the Special component with the specialOffers data */}
				<Special offers={specialOffers} />
			</div>
		</div>
	);
};

export default SpecialOfTheWeek;

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './custom-toastify.css';
import { useTranslation } from 'react-i18next';

const CartItemAddedNotification = ({ productName }) => {
	const { t } = useTranslation();
	return (
		<div className=' text-blue-400 px-4 py-2 font-bold rounded-lg'>
			<p>
				{productName} {t('productAdded')}
			</p>
		</div>
	);
};

export const showCartItemAddedNotification = (productName) => {
	toast.success(<CartItemAddedNotification productName={productName} />, {
		position: 'bottom-right',
		autoClose: 600, // Adjust the autoClose time as needed
		icon: false,
	});
};

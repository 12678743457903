import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';
import CartItem from './CartItem';
import { SidebarContext } from './contexts/SidebarContext';
import { CartContext } from './contexts/CartContext';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
	const { t } = useTranslation();
	const { isOpen, handleClose } = useContext(SidebarContext);
	const { cart, clearCart, itemAmount } = useContext(CartContext);
	const [isCheckOutDisabled, setIsCheckOutDisabled] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);

	// Function to close the sidebar and navigate to the checkout page
	const handleCheckout = () => {
		handleClose(); // Close the sidebar
		// You can add any additional logic here before navigating to the checkout page
	};

	useEffect(() => {
		let total = 0;

		if (cart && cart.length > 0) {
			total = cart.reduce(
				(accumulator, currentItem) =>
					accumulator + (currentItem.initialPrice * currentItem.amount || 0),
				0
			);
		}

		setTotalPrice(total);
	}, [cart]);

	useEffect(() => {
		if (isNaN(itemAmount) || cart.length === 0) {
			setIsCheckOutDisabled(true);
		} else {
			setIsCheckOutDisabled(false);
		}
	}, [itemAmount, cart]);

	const handleShoppingBagIsNaN = () => {
		if (!isCheckOutDisabled) {
			handleCheckout();
		}
	};

	return (
		<div
			className={`${
				isOpen ? 'right-0' : '-right-full'
			} w-full bg-white fixed top-0 h-full shadow-2xl md:w-[35vw]  xl:max-w-[30vw] transition-all duration-300 z-20 px-4 lg:px-[35px] `}
		>
			<div className='flex items-center justify-between py-6 border-b'>
				<div className='uppercase text-sm font-semibold '>
					{isNaN(itemAmount)
						? 'Invalid Shopping Bag'
						: `Shopping Bag (${itemAmount})`}
				</div>
				<div
					onClick={handleClose}
					className='cursor-pointer w-8 h-8 flex justify-center items-center'
				>
					<IoMdArrowForward className='text-2xl' />
				</div>
			</div>

			<div className='flex flex-col gap-y-2 h-[420px] md:h-[380px] lg:h-[380px] overflow-y-auto overflow-x-hidden border-b xl:h-[520px]'>
				{cart.map((item) => {
					return <CartItem item={item} key={item.id} />;
				})}
			</div>

			<div className='flex flex-col gap-y-3 py-4 mt-4'>
				<div className='flex w-full justify-between items-center'>
					<div
						onClick={clearCart}
						className='cursor-pointer py-4 bg-red-500 text-white w-12 h-12 flex justify-center items-center text-xl'
					>
						<FiTrash2 />
					</div>
					<div className='text-center'>
						<p>Total Price: {`$${totalPrice.toFixed(2)}`}</p>
					</div>
				</div>
			</div>

			<div>
				{isCheckOutDisabled ? (
					<div className='bg-slate-400 cursor-not-allowed flex justify-center p-4 items-center text-white w-full font-medium'>
						{t('checkoutBtn')}
					</div>
				) : (
					<Link
						to='/checkout'
						onClick={handleShoppingBagIsNaN}
						className='bg-blue-400 flex justify-center p-4 items-center text-white w-full font-medium'
					>
						{t('checkoutBtn')}
					</Link>
				)}
			</div>
		</div>
	);
};

export default Sidebar;

import { useContext, useState, useEffect, useRef } from 'react';
import { ProductContext } from './contexts/ProductContext';
import Product from './Product';
import GoTopButton from './GoTopButton';
import Pagination from './Pagination';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import catSeafood from '../catseafood.png';
import catMeat from '../catmeat.webp';
import catGoods from '../catgoods.jpg';
import catOil from '../catoil.jpeg';
import catRice from '../catrice.webp';
import catKitchen from '../catkitchen.png';
import catSeasoning from '../catseasoning.png';
import catInstaFood from '../catinstafood.png';
import catPaste from '../catpaste.png';
import catFrz from '../catfrzgood.png';
//

const Home = () => {
	const { t } = useTranslation();
	const { products } = useContext(ProductContext);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const contentRef = useRef();

	const filteredCategories = [
		'frozen seafood',
		'frozen meat',
		'frozen goods',
		'sauce & oil',
		'grains & flour',
		'Dry goods',
		'oil',
		'kitchen supplies',
		'Seasonings',
		'instant food',
		'peppers & bean pastes',
	];

	const categoryIcons = {
		'frozen seafood': catSeafood,
		'frozen meat': catMeat,
		'frozen goods': catFrz,
		'sauce & oil': catOil,
		'grains & flour': catRice,
		'Dry goods': catGoods,
		'kitchen supplies': catKitchen,
		Seasonings: catSeasoning,
		'instant food': catInstaFood,
		'peppers & bean pastes': catPaste,
	};

	const filteredProducts = products.filter((item) =>
		filteredCategories.includes(item.category)
	);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const itemsToDisplay = filteredProducts.slice(startIndex, endIndex);
	const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
	const uniqueCategories = Array.from(
		new Set(filteredProducts.map((product) => product.category))
	);

	useEffect(() => {
		contentRef.current.scrollIntoView({ behavior: 'smooth' });
	}, [currentPage]);

	const paginate = (pageNumber) => {
		if (pageNumber >= 1 && pageNumber <= totalPages) {
			setCurrentPage(pageNumber);
		}
	};

	return (
		<>
			<section>
				<div className='container mx-auto' ref={contentRef}>
					{/* Categories Grid for Desktop */}
					<ul className='grid grid-cols-2 sm:grid-cols-4 gap-4 p-4 bg-gray-50 rounded-xl max-w-full mx-auto'>
						{uniqueCategories.map((category) => (
							<li key={category} className='text-center'>
								<Link
									to={`/category/${category}`}
									className='block p-4 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-200 transform hover:-translate-y-1'
								>
									<div className='bg-white rounded-full p-4 mx-auto mb-4 flex items-center justify-center'>
										<img
											src={categoryIcons[category]}
											alt={category}
											className='w-16 h-16 object-contain'
										/>
									</div>
									<span className='text-base font-medium text-gray-600'>
										{t(category)}
									</span>
								</Link>
							</li>
						))}
					</ul>

					<div className='my-5'>
						<h2 className='my-4 text-center uppercase font-bold'>
							{t('noticeHome')}
						</h2>
						<div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0 px-3'>
							{itemsToDisplay.map((product) => (
								<Product key={product.id} product={product} />
							))}
						</div>
					</div>

					<Pagination
						itemsPerPage={itemsPerPage}
						paginate={paginate}
						currentPage={currentPage}
						totalItems={filteredProducts.length}
					/>
					<GoTopButton />
				</div>
			</section>
		</>
	);
};

export default Home;
